// @ts-strict-ignore
import React from 'react'

import styled from '@emotion/styled'
import { get } from 'lodash'
import AdvancedOptions from 'v2/views/List/AdvancedOptions'

import { supportsItemsPerPage } from 'features/records/utils/supportsItemsPerPage'
import { supportsRowsPerPage } from 'features/records/utils/supportsRowsPerPage'
import FieldPicker from 'features/studio/ui/FieldPicker'
import {
    BoardTitleSizeControlCardStyle,
    BoardTitleSizeControlIconStyle,
} from 'features/views/ListView/BoardView/BoardView.css'
import {
    CardSizeControlCardStyle,
    CardSizeIconInnerContainer,
    CardSizeIconInnerRectangle,
    CardSizeIconOuterContainer,
} from 'features/views/ListView/CardView/CardView.css'

import {
    Box,
    Checkbox,
    Collapse,
    ConditionalWrapper,
    DatePicker,
    Dropdown,
    Flex,
    Heading,
    Text,
} from 'v2/ui'
import { MAX_COLUMNS } from 'v2/ui/components/kanbanUtils'
import ProtectedFeature from 'v2/ui/utils/ProtectedFeature'

import { RadioCard, RadioCardGroup } from 'ui/components/Radio'
import { Body } from 'ui/components/Text'

import { CardStyleSettings } from './CardStyleSettings'
import { displayType } from './DisplayTypes'
import LayoutButton from './LayoutButton'

type ListViewDisplaySettingsProps = {
    object: ObjectDto
    displayTypes: Partial<Record<ListViewDisplay, displayType>>
    config: ListViewOptions
    setConfig: (config: Partial<ListViewOptions>) => void
    getDefaultPageSize?: (display?: string) => number
    getPageSizeOptions?: (display?: string) => number[]
    onDisplayChange?: (displayType: string) => void
    showPageSizeOptions?: boolean
}

const ListViewDisplaySettings = ({
    object,
    displayTypes,
    config,
    setConfig,
    getPageSizeOptions,
    getDefaultPageSize,
    onDisplayChange,
    showPageSizeOptions,
}: ListViewDisplaySettingsProps) => {
    const objectToFieldOptions = (
        object: ObjectDto,
        filter: any,
        provideOptionLabel?: (field: FieldDto, object: ObjectDto) => void
    ) => {
        let { fields } = object
        if (!fields) return []

        if (filter) {
            fields = fields.filter(filter)
        }
        return fields.map((field: FieldDto) => ({
            label: provideOptionLabel ? provideOptionLabel(field, object) : field.label,
            value: field._sid,
        }))
    }

    const changeDisplay = (value: ListViewDisplay) => {
        const patch: { display: ListViewDisplay; pageSize?: number } = { display: value }
        // Make sure the page size we end up with is one of the
        // available page sizes for the newly selected display type.
        const availablePageSizes = getPageSizeOptions?.(value) ?? []
        if (
            getDefaultPageSize &&
            config.pageSize &&
            !availablePageSizes.includes(config.pageSize)
        ) {
            patch.pageSize = getDefaultPageSize(value)
        }
        setConfig(patch)
        if (onDisplayChange) {
            onDisplayChange(value)
        }
    }

    const pageSizeOptions =
        getPageSizeOptions?.(config.display).map((x) => ({
            label: x,
            value: x,
        })) ?? []

    const pageRowsOptions = [
        { label: 3, value: 3 },
        { label: 6, value: 6 },
        { label: 9, value: 9 },
        { label: 12, value: 12 },
    ]

    const pageRowsDefaultValue = 6

    const kanBanStatusField =
        (config.display === 'kanban' || config.display === 'boardV2') &&
        config.statusField &&
        object.fields.find((field) => field._sid === config.statusField)
    const kanBanStatusFieldOptions = (get(kanBanStatusField, 'options.options') || []).concat([
        { label: 'None', value: null },
    ])

    const dateFilter = (field: FieldDto) =>
        (field.type === 'date' || field.type === 'datetime') &&
        get(field.connection_options, 'is_disabled', false) === false

    const endDatefilter = (field: FieldDto) => {
        return dateFilter(field) && field._sid !== config.startDateField
    }

    // If there is only one date OR datetime field,
    // don't display the select for the end date
    const dateOptions = objectToFieldOptions(object, endDatefilter)

    let singleDateField = !dateOptions.length

    return (
        <div
            style={{
                width: '420px',
                maxHeight: '70vh',
                overflowY: 'auto',
            }}
        >
            <Heading as="h5" size="md" p={3} value="Layout" />
            <FlexDisplayOptions>
                {Object.entries(displayTypes).map(
                    ([type, options = {} as displayType]: [
                        type: ListViewDisplay,
                        options: displayType
                    ]) => {
                        const isSelected = config.display === type

                        if (!options.isReleased) return null

                        return (
                            <FlexDisplayItem key={type}>
                                <ConditionalWrapper
                                    condition={!!options.protectedFeature}
                                    wrapper={(children: React.ReactNode) => (
                                        <ProtectedFeature
                                            feature={options.protectedFeature}
                                            indicatorTop="0px"
                                            indicatorRight="-10px"
                                            display="inline-block"
                                            flex="1"
                                            width="130px"
                                            height="100%"
                                        >
                                            {children}
                                        </ProtectedFeature>
                                    )}
                                >
                                    <LayoutButton
                                        //@ts-expect-error
                                        view={options}
                                        isSelected={isSelected}
                                        onClick={() => changeDisplay(type)}
                                        data-testid={`${options.title}-button`}
                                    />
                                </ConditionalWrapper>
                            </FlexDisplayItem>
                        )
                    }
                )}
            </FlexDisplayOptions>
            <Flex m="10px">
                {showPageSizeOptions && (
                    <Flex mt={2} align="center">
                        <Flex style={{ flexBasis: '100%' }}>
                            {supportsItemsPerPage(config) ? (
                                <>
                                    <Text variant="dataSourceSubTitle" mr={2}>
                                        Records per page:
                                    </Text>
                                    <Dropdown
                                        value={
                                            config.pageSize || getDefaultPageSize?.(config.display)
                                        }
                                        options={pageSizeOptions}
                                        onChange={(value: number) => setConfig({ pageSize: value })}
                                        width={50}
                                        isSearchable={false}
                                    />
                                </>
                            ) : supportsRowsPerPage(config) ? (
                                <>
                                    <Text variant="dataSourceSubTitle" mr={2}>
                                        Rows per page:
                                    </Text>
                                    <Dropdown
                                        value={config.cardPageRows || pageRowsDefaultValue}
                                        options={pageRowsOptions}
                                        onChange={(value: number) =>
                                            setConfig({ cardPageRows: value })
                                        }
                                        width={50}
                                        isSearchable={false}
                                    />
                                </>
                            ) : null}
                        </Flex>
                    </Flex>
                )}

                {(config.display === 'kanban' || config.display === 'boardV2') && (
                    <Box>
                        <Text variant="adminFieldLabel" mt="0">
                            Board Status
                        </Text>
                        <Text variant="checkbox">Choose a single select status field</Text>
                        <FieldPicker
                            objectId={object._sid}
                            style={{ width: '405px', marginTop: '8px' }}
                            filter={(field) =>
                                field.type === 'dropdown' &&
                                get(field.connection_options, 'is_disabled', false) === false
                            }
                            placeholder="Select status field"
                            value={config.statusField}
                            onChange={(fieldId) => setConfig({ statusField: fieldId })}
                            isDisabled={false}
                            autoSelectSingleOption
                        />
                        {config.display === 'kanban' && (
                            <Checkbox
                                isChecked={config.userCanUpdateStatusField}
                                variant="admin"
                                mt={1}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setConfig({ userCanUpdateStatusField: e.target.checked })
                                }
                            >
                                Let user customize
                            </Checkbox>
                        )}
                        <Collapse isOpen={!!kanBanStatusField}>
                            {kanBanStatusField && (
                                <>
                                    <Text variant="adminFieldLabel">Columns</Text>
                                    <Text m={'0 0 8px 0'} variant="checkbox">
                                        Choose what columns you want to display
                                    </Text>
                                    <Dropdown
                                        value={config.statusColumns}
                                        options={kanBanStatusFieldOptions}
                                        isMulti
                                        allowNullValues
                                        onChange={(value: (string | null)[]) =>
                                            setConfig({ statusColumns: value })
                                        }
                                    />
                                    {config.display === 'kanban' &&
                                        !config.userCanUpdateStatusField && (
                                            <Checkbox
                                                isChecked={config.userCanUpdateStatusColumns}
                                                variant="admin"
                                                mt={1}
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) =>
                                                    setConfig({
                                                        userCanUpdateStatusColumns:
                                                            e.target.checked,
                                                    })
                                                }
                                            >
                                                Let user customize
                                            </Checkbox>
                                        )}
                                    <Collapse
                                        isOpen={
                                            !!(
                                                config.statusColumns &&
                                                config.statusColumns.length > MAX_COLUMNS
                                            )
                                        }
                                    >
                                        <Text variant="error" mt={2}>
                                            Please select a maximum of {MAX_COLUMNS} columns
                                        </Text>
                                    </Collapse>
                                </>
                            )}
                        </Collapse>
                    </Box>
                )}
                {config.display === 'tableV2' && (
                    <Box w="100%" mt={2}>
                        <Box display="inline-block">
                            <Checkbox
                                mr={2}
                                variant="admin"
                                isChecked={config.tablePinPrimaryColumn ?? true}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setConfig({ tablePinPrimaryColumn: e.target.checked })
                                }}
                            >
                                Pin first column
                            </Checkbox>
                        </Box>
                    </Box>
                )}
                {config.display === 'boardV2' && (
                    <Box width="100%" display="flex" flexDirection="column">
                        <Box>
                            <Text variant="adminFieldLabel" mt="0">
                                Title size
                            </Text>
                            <Box role="group">
                                <RadioCardGroup
                                    value={config.boardTitleSize ?? 'medium'}
                                    onValueChange={(value: string) => {
                                        const newValue =
                                            (value as ListViewOptions['boardTitleSize']) ||
                                            undefined

                                        setConfig({
                                            boardTitleSize: newValue,
                                        })
                                    }}
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 1fr 1fr',
                                    }}
                                >
                                    <RadioCard
                                        value="small"
                                        icon={() => (
                                            <Box className={BoardTitleSizeControlIconStyle}>
                                                <Body size="m" weight="bold">
                                                    Abc
                                                </Body>
                                            </Box>
                                        )}
                                        className={BoardTitleSizeControlCardStyle}
                                    >
                                        Small
                                    </RadioCard>
                                    <RadioCard
                                        value="medium"
                                        icon={() => (
                                            <Box className={BoardTitleSizeControlIconStyle}>
                                                <Body size="l" weight="bold">
                                                    Abc
                                                </Body>
                                            </Box>
                                        )}
                                        className={BoardTitleSizeControlCardStyle}
                                    >
                                        Medium
                                    </RadioCard>
                                    <RadioCard
                                        value="large"
                                        icon={() => (
                                            <Box className={BoardTitleSizeControlIconStyle}>
                                                <Body size="xl" weight="bold">
                                                    Abc
                                                </Body>
                                            </Box>
                                        )}
                                        className={BoardTitleSizeControlCardStyle}
                                    >
                                        Large
                                    </RadioCard>
                                </RadioCardGroup>
                            </Box>
                        </Box>
                        <Box>
                            <Text variant="adminFieldLabel" mt="0">
                                Label style
                            </Text>
                            <Box role="group">
                                <RadioCardGroup
                                    value={config.boardLabelStyle ?? ''}
                                    onValueChange={(value: string) => {
                                        const newValue =
                                            (value as ListViewOptions['boardLabelStyle']) ||
                                            undefined

                                        setConfig({
                                            boardLabelStyle: newValue,
                                        })
                                    }}
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 1fr',
                                    }}
                                >
                                    <RadioCard value="" icon={{ name: 'EyeOff' }}>
                                        None
                                    </RadioCard>
                                    <RadioCard value="text" icon={{ name: 'Text' }}>
                                        Text
                                    </RadioCard>
                                </RadioCardGroup>
                            </Box>
                        </Box>
                        <Box>
                            <Text variant="adminFieldLabel" mt="0">
                                Fields style
                            </Text>
                            <Box role="group">
                                <RadioCardGroup
                                    value={config.boardFieldStyle ?? 'list'}
                                    onValueChange={(value: string) => {
                                        const newValue = value as ListViewOptions['boardFieldStyle']

                                        setConfig({
                                            boardFieldStyle: newValue,
                                        })
                                    }}
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 1fr',
                                    }}
                                >
                                    <RadioCard value="list" icon={{ name: 'LayoutList' }}>
                                        List
                                    </RadioCard>
                                    <RadioCard value="tags" icon={{ name: 'Tags' }}>
                                        Tags
                                    </RadioCard>
                                </RadioCardGroup>
                            </Box>
                        </Box>
                    </Box>
                )}

                {config.display === 'cardV2' && (
                    <Box width="100%" display="flex" flexDirection="column">
                        <Box>
                            <Text variant="adminFieldLabel" mt="0">
                                Card size
                            </Text>
                            <Box role="group">
                                <RadioCardGroup
                                    value={config.cardCardSize ?? 'medium'}
                                    onValueChange={(value: string) => {
                                        const newValue =
                                            (value as ListViewOptions['cardCardSize']) || undefined

                                        setConfig({
                                            cardCardSize: newValue,
                                        })
                                    }}
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 1fr 1fr',
                                    }}
                                >
                                    <RadioCard
                                        value="medium"
                                        icon={() => (
                                            <Box className={CardSizeIconOuterContainer}>
                                                <div className={CardSizeIconInnerContainer}>
                                                    <div className={CardSizeIconInnerRectangle} />
                                                    <div className={CardSizeIconInnerRectangle} />
                                                    <div className={CardSizeIconInnerRectangle} />
                                                </div>
                                            </Box>
                                        )}
                                        className={CardSizeControlCardStyle}
                                    >
                                        Regular
                                    </RadioCard>
                                    <RadioCard
                                        value="large"
                                        icon={() => (
                                            <Box className={CardSizeIconOuterContainer}>
                                                <div className={CardSizeIconInnerContainer}>
                                                    <div className={CardSizeIconInnerRectangle} />
                                                    <div className={CardSizeIconInnerRectangle} />
                                                </div>
                                            </Box>
                                        )}
                                        className={CardSizeControlCardStyle}
                                    >
                                        Large
                                    </RadioCard>
                                    <RadioCard
                                        value="full_width"
                                        icon={() => (
                                            <Box className={CardSizeIconOuterContainer}>
                                                <div className={CardSizeIconInnerContainer}>
                                                    <div className={CardSizeIconInnerRectangle} />
                                                </div>
                                            </Box>
                                        )}
                                        className={CardSizeControlCardStyle}
                                    >
                                        Full-width
                                    </RadioCard>
                                </RadioCardGroup>
                            </Box>
                        </Box>
                        <CardStyleSettings
                            config={config}
                            value={config.cardCardStyle ?? 'border'}
                            onChange={(value: string) => {
                                const newValue =
                                    (value as ListViewOptions['cardCardStyle']) || undefined

                                setConfig({
                                    cardCardStyle: newValue,
                                })
                            }}
                        />
                        <Box>
                            <Text variant="adminFieldLabel" mt="0">
                                Title size
                            </Text>
                            <Box role="group">
                                <RadioCardGroup
                                    value={config.cardTitleSize ?? 'medium'}
                                    onValueChange={(value: string) => {
                                        const newValue =
                                            (value as ListViewOptions['cardTitleSize']) || undefined

                                        setConfig({
                                            cardTitleSize: newValue,
                                        })
                                    }}
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 1fr 1fr',
                                    }}
                                >
                                    <RadioCard
                                        value="small"
                                        icon={() => (
                                            <Box className={BoardTitleSizeControlIconStyle}>
                                                <Body size="m" weight="bold">
                                                    Abc
                                                </Body>
                                            </Box>
                                        )}
                                        className={BoardTitleSizeControlCardStyle}
                                    >
                                        Small
                                    </RadioCard>
                                    <RadioCard
                                        value="medium"
                                        icon={() => (
                                            <Box className={BoardTitleSizeControlIconStyle}>
                                                <Body size="l" weight="bold">
                                                    Abc
                                                </Body>
                                            </Box>
                                        )}
                                        className={BoardTitleSizeControlCardStyle}
                                    >
                                        Medium
                                    </RadioCard>
                                    <RadioCard
                                        value="large"
                                        icon={() => (
                                            <Box className={BoardTitleSizeControlIconStyle}>
                                                <Body size="xl" weight="bold">
                                                    Abc
                                                </Body>
                                            </Box>
                                        )}
                                        className={BoardTitleSizeControlCardStyle}
                                    >
                                        Large
                                    </RadioCard>
                                </RadioCardGroup>
                            </Box>
                        </Box>
                        <Box>
                            <Text variant="adminFieldLabel" mt="0">
                                Label style
                            </Text>
                            <Box role="group">
                                <RadioCardGroup
                                    value={config.cardLabelStyle ?? ''}
                                    onValueChange={(value: string) => {
                                        const newValue =
                                            (value as ListViewOptions['cardLabelStyle']) ||
                                            undefined

                                        setConfig({
                                            cardLabelStyle: newValue,
                                        })
                                    }}
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 1fr',
                                    }}
                                >
                                    <RadioCard value="" icon={{ name: 'EyeOff' }}>
                                        None
                                    </RadioCard>
                                    <RadioCard value="text" icon={{ name: 'Text' }}>
                                        Text
                                    </RadioCard>
                                </RadioCardGroup>
                            </Box>
                        </Box>
                    </Box>
                )}

                {config.display === 'single_record' && (
                    <Box mt={2}>
                        <Checkbox
                            mr={2}
                            variant="admin"
                            isChecked={config.disableCreateForm != true}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setConfig({ disableCreateForm: !e.target.checked })
                            }
                        >
                            Show create form view when empty
                        </Checkbox>
                    </Box>
                )}
                {config.display === 'calendar' && (
                    <Box mt="5px" w="100%">
                        <Text variant="adminFieldLabel">Show calendar by</Text>
                        <FieldPicker
                            objectId={object._sid}
                            style={{
                                width: '100%',
                                marginTop: '8px',
                                marginBottom: '1rem',
                            }}
                            filter={dateFilter}
                            placeholder="Select start date field"
                            value={config.startDateField}
                            onChange={(fieldId) => {
                                setConfig({
                                    startDateField: fieldId,
                                    endDateField: undefined,
                                })
                            }}
                            noOptionsPlaceholder="No date options available"
                            autoSelectSingleOption
                            isDisabled={false}
                        />
                        <Collapse isOpen={!!config.startDateField && !singleDateField}>
                            <Text variant="adminFieldLabel">End date (optional)</Text>
                            <FieldPicker
                                objectId={object._sid}
                                style={{
                                    width: '100%',
                                    marginTop: '8px',
                                    marginBottom: '1rem',
                                }}
                                filter={endDatefilter}
                                placeholder="Select end date field"
                                value={config.endDateField}
                                onChange={(fieldId) => setConfig({ endDateField: fieldId })}
                                noOptionsPlaceholder="No date options available"
                                isDisabled={false}
                            />
                        </Collapse>
                        <AdvancedOptions>
                            <Flex basis="100%" align="center" justify="space-between" mt={2}>
                                <Text variant="adminFieldLabel" mr={2}>
                                    Default view
                                </Text>
                                <div>
                                    <Dropdown
                                        maxMenuHeight={115}
                                        usePortal={false}
                                        variant="settings"
                                        value={config.defaultCalendarView || 'Month'}
                                        options={[
                                            { label: 'Month', value: 'Month' },
                                            { label: 'Week', value: 'Week' },
                                            { label: 'Work Week', value: 'WorkWeek' },
                                            { label: 'Day', value: 'Day' },
                                            { label: 'Agenda', value: 'Agenda' },
                                        ]}
                                        onChange={(val: any) =>
                                            setConfig({ defaultCalendarView: val })
                                        }
                                    />
                                </div>
                            </Flex>
                            <Flex basis="100%" align="center" justify="space-between" mt={2}>
                                <Text variant="adminFieldLabel" mr={2}>
                                    Start week on
                                </Text>
                                <div>
                                    <Dropdown
                                        maxMenuHeight={115}
                                        usePortal={false}
                                        variant="settings"
                                        value={config.weekStartDay || '0'}
                                        options={[
                                            { label: 'Sunday', value: '0' },
                                            { label: 'Monday', value: '1' },
                                            { label: 'Tuesday', value: '2' },
                                            { label: 'Wednesday', value: '3' },
                                            { label: 'Thursday', value: '4' },
                                            { label: 'Friday', value: '5' },
                                            { label: 'Saturday', value: '6' },
                                        ]}
                                        onChange={(val: any) => setConfig({ weekStartDay: val })}
                                    />
                                </div>
                            </Flex>
                            <Flex basis="100%" align="center" justify="space-between" mt={2}>
                                <Text variant="adminFieldLabel" mr={2}>
                                    Default date
                                </Text>
                                <div>
                                    <Dropdown
                                        maxMenuHeight={115}
                                        usePortal={false}
                                        variant="settings"
                                        value={config.defaultDateOption || 'Today'}
                                        options={[
                                            { label: 'Today', value: 'Today' },
                                            { label: 'Next Week', value: 'NextWeek' },
                                            { label: 'Next Month', value: 'NextMonth' },
                                            { label: 'Last Week', value: 'LastWeek' },
                                            { label: 'Last Month', value: 'LastMonth' },
                                            { label: 'Custom', value: 'Custom' },
                                        ]}
                                        onChange={(val: any) =>
                                            setConfig({
                                                defaultDateOption: val,
                                                defaultDate: null,
                                            })
                                        }
                                    />
                                </div>
                            </Flex>
                            <Collapse isOpen={config.defaultDateOption === 'Custom'} mt={2}>
                                <Text variant="dataSourceSubTitle">Select the custom date</Text>
                                <DatePicker
                                    //@ts-expect-error
                                    style={{ width: '100%', marginBottom: '1rem' }}
                                    onChange={(dt) =>
                                        setConfig({
                                            defaultDate: dt.format('YYYY-MM-DD'),
                                        })
                                    }
                                    showTimeSelect={false}
                                    selected={config.defaultDate}
                                />
                            </Collapse>
                        </AdvancedOptions>
                    </Box>
                )}
            </Flex>
        </div>
    )
}
export default ListViewDisplaySettings

const FlexDisplayItem = styled('div')`
    justify-content: start;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 12px;
    flex-basis: 25%;
`

const FlexDisplayOptions = styled(Flex)`
    justify-content: start;
    gap: 12px 8px;
    margin-bottom: 12px;
    margin-left: 10px;
`
