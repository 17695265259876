// @ts-nocheck
import { cloneDeep } from 'lodash'

export type BundleRecordField = {
    fieldId: string
    value: any
}
export type BundleRecord = {
    recordId: string
    fields: BundleRecordField[]
}

export type BundleField = {
    id: string
    name: string
    type: FieldType
    isPrimary: boolean
    relatedObjectId?: any
}

export type BundleObject = {
    id: string
    name: string
    fields: BundleField[]
    records: BundleRecord[]
}

export type BundleApp = {
    id: string
    name: string
    objects: BundleObject[]
}

export type Bundle = {
    apps: BundleApp[]
}

const BUNDLE_TEMPLATE = {
    metadata: { fileVersion: 1 },
    apps: [],
}

const APP_TEMPLATE = {
    properties: {
        name: null,
        options: {
            group: 'General',
            theme: {
                icon: 'fa-book',
                navColor: 'light',
                navIcons: true,
                brandColor: '#E11D48',
            },
            charts: false,
            ui_version: 2,
            pin_to_app_bar: true,
            new_create_form: true,
            showBreadcrumbs: true,
            multi_data_source: true,
            enable_comment_feed: true,
            enable_many_to_many: true,
            secondary_navigation: true,
            show_app_users_splash: true,
            stacker_relationships: true,
            enable_new_app_settings: true,
            roles__default_user_role: 'role.custom.user',
        },
    },
    roles: [
        {
            exportId: 'role.system.internal_admin',
            label: 'Admin',
            options: {
                type: 'internal',
                rights: ['admin', 'all_data_access'],
                all_pages: true,
                system_role: true,
            },
        },
        { exportId: 'role.custom.user', label: 'User', options: {} },
    ],
    objects: [],
}

const OBJECT_TEMPLATE = {
    metadata: {
        exportId: null,
        name: null,
        options: { is_using_new_record_sid_format: true },
        fields: [],
    },
    data: {
        records: [],
    },
}

const FIELD_TEMPLATE = {
    exportId: null,
    name: null,
    type: 'string',
    isPrimary: false,
    options: {},
    link_target_object_sid: null,
    rollup_target_object_sid: null,
    connectionOptions: {},
    syntheticFieldType: null,
}

const RECORD_TEMPLATE = {
    _sid: null,
    fields: [],
}

const RECORD_FIELD_TEMPLATE = {
    api_name: null,
    value: null,
}
const TRANSLATED_FIELD_TYPES = {
    relationship: 'lookup',
    multi_relationship: 'multi_lookup',
}

export const bundleBuilder = (bundle: Bundle): any => {
    const result = cloneDeep(BUNDLE_TEMPLATE)

    for (const app of bundle.apps) {
        const appObject = cloneDeep(APP_TEMPLATE)
        appObject.properties.name = app.name

        appObject.objects = []
        for (const object of app.objects) {
            const objectObject = cloneDeep(OBJECT_TEMPLATE)
            objectObject.metadata.exportId = object.id
            objectObject.metadata.name = object.name
            objectObject.metadata.fields = []
            for (const field of object.fields) {
                const fieldObject = cloneDeep(FIELD_TEMPLATE)
                fieldObject.exportId = field.id
                fieldObject.name = field.name

                if (field.type in TRANSLATED_FIELD_TYPES) {
                    fieldObject.type = TRANSLATED_FIELD_TYPES[field.type]
                } else {
                    fieldObject.type = field.type
                }
                fieldObject.isPrimary = field.isPrimary
                fieldObject.link_target_object_sid = field.relatedObjectId
                objectObject.metadata.fields.push(fieldObject)
            }

            for (const record of object.records) {
                const recordObject = cloneDeep(RECORD_TEMPLATE)
                recordObject._sid = record.recordId
                recordObject.fields = []
                for (const field of record.fields) {
                    const fieldObject = cloneDeep(RECORD_FIELD_TEMPLATE)
                    fieldObject.api_name = field.fieldId
                    fieldObject.value = field.value

                    const fieldDef = objectObject.metadata.fields.find(
                        (f) => f.exportId === field.fieldId
                    )
                    if (fieldDef?.type === 'multi_lookup') {
                        fieldObject.value = fieldObject.value.split(',')
                    }

                    recordObject.fields.push(fieldObject)
                }
                objectObject.data.records.push(recordObject)
            }
            appObject.objects.push(objectObject)
        }
        result.apps.push(appObject)
    }
    return result
}
