export const SIMPLIFIED_APP_SCHEMA = {
    name: 'app_schema',
    strict: true,
    schema: {
        $schema: 'http://json-schema.org/draft-07/schema#',
        type: 'object',
        properties: {
            apps: {
                type: 'array',
                items: {
                    type: 'object',
                    title: 'App',
                    description: 'Represents a web application containing multiple data objects',
                    properties: {
                        id: {
                            type: 'string',
                        },
                        name: {
                            type: 'string',
                        },
                        objects: {
                            type: 'array',
                            items: {
                                type: 'object',
                                title: 'Object',
                                description: 'Represents a data object or table in the application',
                                properties: {
                                    id: {
                                        type: 'string',
                                    },
                                    name: {
                                        type: 'string',
                                    },
                                    fields: {
                                        type: 'array',
                                        items: {
                                            type: 'object',
                                            title: 'Field',
                                            description:
                                                'Represents a field or column in the data object',
                                            properties: {
                                                id: {
                                                    type: 'string',
                                                },
                                                name: {
                                                    type: 'string',
                                                },
                                                isPrimary: {
                                                    description: 'is the display name field',
                                                    type: 'boolean',
                                                },
                                                type: {
                                                    type: 'string',
                                                    enum: [
                                                        'checkbox',
                                                        'currency',
                                                        'date',
                                                        'datetime',
                                                        'dropdown',
                                                        'document',
                                                        'multi_select',
                                                        'number',
                                                        'percentage',
                                                        'string',
                                                        'relationship',
                                                        'multi_relationship',
                                                    ],
                                                },
                                                relatedObjectId: {
                                                    description:
                                                        'the id of the target object if this is a relationship type field',
                                                    type: ['string', 'null'],
                                                },
                                                dropdownOptions: {
                                                    type: ['array', 'null'],
                                                    items: { type: 'string' },
                                                },
                                            },
                                            required: [
                                                'id',
                                                'name',
                                                'type',
                                                'isPrimary',
                                                'relatedObjectId',
                                                'dropdownOptions',
                                            ],
                                            additionalProperties: false,
                                        },
                                    },
                                    records: {
                                        type: 'array',
                                        items: {
                                            type: 'object',
                                            title: 'Record',
                                            properties: {
                                                recordId: {
                                                    type: 'string',
                                                },
                                                fields: {
                                                    type: 'array',
                                                    items: {
                                                        type: 'object',
                                                        title: 'value',
                                                        properties: {
                                                            fieldId: {
                                                                type: 'string',
                                                            },
                                                            value: {
                                                                type: [
                                                                    'string',
                                                                    'number',
                                                                    'boolean',
                                                                    'null',
                                                                ],
                                                            },
                                                        },
                                                        required: ['fieldId', 'value'],
                                                        additionalProperties: false,
                                                    },
                                                },
                                            },
                                            required: ['recordId', 'fields'],
                                            additionalProperties: false,
                                        },
                                    },
                                    listViews: {
                                        type: 'array',
                                        items: {
                                            type: 'object',
                                            title: 'ListView',
                                            properties: {
                                                title: {
                                                    type: 'string',
                                                },
                                                layout: {
                                                    type: 'string',
                                                    enum: ['table', 'cards', 'kanban'],
                                                },
                                                fieldIds: {
                                                    type: 'array',
                                                    items: {
                                                        type: 'string',
                                                    },
                                                },
                                                kanbanOptions: {
                                                    type: ['object', 'null'],
                                                    properties: {
                                                        statusFieldId: {
                                                            type: 'string',
                                                            description:
                                                                'must be a single select/dropdown field',
                                                        },
                                                        selectedStatuses: {
                                                            type: 'array',
                                                            items: {
                                                                type: 'string',
                                                                description:
                                                                    'must be a value of the statusFieldId',
                                                            },
                                                        },
                                                    },
                                                    required: ['statusFieldId', 'selectedStatuses'],
                                                    additionalProperties: false,
                                                },
                                            },
                                            required: [
                                                'title',
                                                'layout',
                                                'fieldIds',
                                                'kanbanOptions',
                                            ],
                                            additionalProperties: false,
                                        },
                                    },
                                },
                                required: ['id', 'name', 'fields', 'records', 'listViews'],
                                additionalProperties: false,
                            },
                        },
                    },
                    required: ['id', 'name', 'objects'],
                    additionalProperties: false,
                },
            },
        },
        required: ['apps'],
        additionalProperties: false,
    },
}
