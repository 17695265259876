import React from 'react'

import { UrlAttributeDisplay } from 'features/views/attributes/UrlAttributeDisplay'

import { BaseCell } from './BaseCell'
import { TableViewCellComponent } from './types'

const MAX_LINES = 1
const MAX_LENGTH = 30
const MAX_OVERFLOW_LENGTH = 300

type UrlCellProps = React.ComponentPropsWithoutRef<typeof BaseCell> & {}

export const UrlCell: TableViewCellComponent<string, UrlCellProps> = ({
    value,
    column,
    isLoading,
    ...props
}) => {
    const { rawOptions, field } = column

    const isEmpty = !isLoading && !value

    return (
        <BaseCell column={column} isEmpty={isEmpty} {...props}>
            <UrlAttributeDisplay
                size={{
                    mobile: 's',
                    tablet: 'm',
                }}
                showOverflowTooltip={{
                    mobile: false,
                    tablet: true,
                }}
                value={value}
                displayAsButton={rawOptions.displayAsButton}
                buttonTitle={rawOptions.buttonTitle}
                displayAsImage={rawOptions.displayAsImage}
                imageAltText={rawOptions.imageAltText}
                openInNewTab={rawOptions.openInNewTab}
                field={field}
                maxLines={MAX_LINES}
                maxLength={MAX_LENGTH}
                maxOverflowLength={MAX_OVERFLOW_LENGTH}
                isLoading={isLoading}
                style={{
                    width: rawOptions.displayAsButton ? 'max-content' : undefined,
                }}
            />
        </BaseCell>
    )
}
