import { useState } from 'react'

import { refetchStacks } from 'data/hooks/stacks/stackOperations'
import { fetchWithAuth } from 'data/utils/fetchWithAuth'

export const useBundleImport = (): 'idle' | 'loading' | 'success' | 'error' => {
    const [state, setState] = useState<'idle' | 'loading' | 'success' | 'error'>('idle')
    const bundleUrl = localStorage.getItem('importBundleAfterLogin')

    if (bundleUrl) {
        const process = async () => {
            try {
                localStorage.removeItem('importBundleAfterLogin')
                setState('loading')
                const bundleResponse = await fetch(bundleUrl)
                if (!bundleResponse.ok) {
                    setState('error')
                    return
                }
                const bundle = await bundleResponse.json()
                const importResponse = await fetchWithAuth('bundles/import/', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ bundle }),
                })
                if (!importResponse.ok) {
                    setState('error')
                    return
                }
                await refetchStacks()
                setState('success')

                // const bundle = await bundleResponse.json()
            } catch (e) {
                console.error(e)
                setState('idle')
            }
        }

        process()
    }

    return state
}
