import React from 'react'

import { Button } from 'ui/components/Button'
import {
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalTabs,
    ModalTabsContent,
    ModalTabsList,
    ModalTabsTrigger,
    ModalTrigger,
} from 'ui/components/Modal'

import { SampleChatAndPreview } from './SampleChatAndPreview'
import { StructuredOutput } from './StructuredOutput'

export function AiPlaygroundModal() {
    return (
        <Modal>
            <ModalTrigger asChild>
                <Button>Create with AI</Button>
            </ModalTrigger>

            <ModalContent flex column style={{ width: '95vw', height: '95vh' }}>
                <ModalHeader title="AI Playground" />
                <ModalTabs defaultValue="tab1" type="underlined" grow flex column>
                    <ModalTabsList aria-label="Aria-Label">
                        <ModalTabsTrigger value="tab1" label="Simple Chat" />
                        <ModalTabsTrigger value="tab2" label="Structured Output" />
                    </ModalTabsList>
                    <ModalTabsContent value="tab1" grow>
                        <SampleChatAndPreview />
                    </ModalTabsContent>
                    <ModalTabsContent value="tab2" grow>
                        <StructuredOutput />
                    </ModalTabsContent>
                </ModalTabs>
                <ModalFooter>Foooter</ModalFooter>
            </ModalContent>
        </Modal>
    )
}
