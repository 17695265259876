import { v4 as uuid } from 'uuid'

export function stopPropagation(event: any) {
    event.stopPropagation()
    event.preventDefault()
}

export function stopAllPointerPropagation() {
    return {
        onClick: stopPropagation,
        onDoubleClick: stopPropagation,
        onPointerDown: stopPropagation,
        onPointerUp: stopPropagation,
        onPointerMove: stopPropagation,
        onPointerCancel: stopPropagation,
        onPointerEnter: stopPropagation,
        onPointerLeave: stopPropagation,
        onPointerOver: stopPropagation,
        onPointerOut: stopPropagation,
        onMouseDown: stopPropagation,
        onMouseUp: stopPropagation,
        onMouseMove: stopPropagation,
        onMouseEnter: stopPropagation,
        onMouseLeave: stopPropagation,
        onMouseOver: stopPropagation,
        onMouseOut: stopPropagation,
        onTouchStart: stopPropagation,
        onTouchEnd: stopPropagation,
        onTouchMove: stopPropagation,
        onTouchCancel: stopPropagation,
    }
}

export function generateUniqueId() {
    return uuid()
}

export function truncateText(text: string, maxLength: number) {
    if (text.length <= maxLength) return text

    return text.slice(0, maxLength) + '…'
}

// The number of characters to keep from the end of the file name,
// right before the file extension.
const FILE_NAME_ENDING_LENGTH = 3
const FILE_EXTENSION_MAX_LENGTH = 4

export function truncateFileName(text: string, maxLength: number) {
    if (text.length <= maxLength) return text

    const extension = text.split('.').pop()
    let extensionLen = 0
    if (extension && extension !== text && extension.length <= FILE_EXTENSION_MAX_LENGTH) {
        // Make sure to take the `.` into account.
        extensionLen = extension.length + 1
    }

    const beforeTruncation = text.slice(0, maxLength - extensionLen - FILE_NAME_ENDING_LENGTH)
    const afterTruncation = text.slice(-FILE_NAME_ENDING_LENGTH - extensionLen)

    return beforeTruncation + '…' + afterTruncation
}

export function makeLineTruncationStyle(maxLines?: number): React.CSSProperties | undefined {
    if (!maxLines) return undefined

    if (maxLines === 1) {
        return {
            whiteSpace: 'nowrap',
        }
    }

    return {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        boxOrient: 'vertical',
        WebkitLineClamp: maxLines,
        lineClamp: maxLines,
        overflow: 'hidden',
    }
}
