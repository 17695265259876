import React, { useState } from 'react'

import { Box } from 'ui/components/Box'

import { SIMPLIFIED_APP_SCHEMA } from './bundles/appSchema'
import { getChatCompletion } from './chatUtils/chatApi'
import { ChatMessageBase } from './chatUtils/chatTypes'
import { ChatBox } from './components/ChatBox'
import { BundleImporter } from './BundleImporter'

const SYSTEM_PROMPT = `You are a chat bot in an app builder. Based on the users input, you will generate an app schema in the specified JSON format.
1. Think about the relationships between objects. Make them one-to-one or one-to-many as appropriate.
2. Don't generate ID columns.
3. Record ids should be unique across the app`

export function StructuredOutput() {
    const [messages, setMessages] = useState<ChatMessageBase[]>([])
    const [previewData, setPreviewData] = useState<string>('')
    async function handlePostMessage(message: string) {
        setMessages((m) => [...m, { role: 'user', content: message }])

        const result = await getChatCompletion(
            [
                { role: 'system', content: SYSTEM_PROMPT },
                ...messages,
                { role: 'user', content: message },
            ],
            undefined,
            SIMPLIFIED_APP_SCHEMA
        )

        try {
            const formattedContent = JSON.parse(result.content || '')
            setPreviewData(JSON.stringify(formattedContent, null, 2))
        } catch (error) {
            console.error('Failed to parse JSON:', error)
            setPreviewData(result.content || '')
        }
    }
    return (
        <Box flex alignItems="stretch" grow height="full" gap="m">
            <Box style={{ flexBasis: '33%' }}>
                <ChatBox
                    messages={messages}
                    onPostMessage={handlePostMessage}
                    placeholder="what do you want to build?"
                />
            </Box>
            <Box grow background="gray100">
                <BundleImporter bundle={previewData} setBundle={setPreviewData} />
            </Box>
        </Box>
    )
}
