import { TableViewColumn, TableViewColumnAlign } from './types'

export function getDefaultColumnAlign(column: TableViewColumn): TableViewColumnAlign {
    switch (column.field.type) {
        case 'checkbox':
            return column.isPrimary ? 'left' : 'center'
        case 'currency':
        case 'number':
            return column.isPrimary ? 'left' : 'right'
        case 'percentage':
            return column.rawOptions.percentageDisplay === 'percentBar' ? 'left' : 'center'
        case 'url': {
            if (column.rawOptions.displayAsImage || column.rawOptions.displayAsButton) {
                return 'center'
            }

            return 'left'
        }
        default:
            return 'left'
    }
}
