import React, { useState } from 'react'

import { Box } from 'ui/components/Box'

import { getChatCompletion } from './chatUtils/chatApi'
import { handleToolCall } from './chatUtils/chatToolsHandler'
import { ChatMessageBase, ChatTool } from './chatUtils/chatTypes'
import { get_weather } from './chatUtils/sampleTools'
import { ChatBox } from './components/ChatBox'

export function SampleChatAndPreview() {
    const [messages, setMessages] = useState<ChatMessageBase[]>([])

    const tools: ChatTool[] = [get_weather]
    const toolHandlers = {
        get_weather: (args) => `The weather for ${args.location} is ${10}${args.unit}`,
    }

    async function handlePostMessage(message: string) {
        setMessages((m) => [...m, { role: 'user', content: message }])

        const result = await getChatCompletion(
            [...messages, { role: 'user', content: message }],
            tools
        )

        console.log(result)
        if (result.tool_calls) {
            const toolResult = await handleToolCall(toolHandlers, result.tool_calls[0])
            if (!toolResult) {
                return
            } else {
                setMessages((m) => [
                    ...m,
                    result,
                    {
                        role: 'tool',
                        tool_call_id: toolResult.tool_call_id,
                        content: toolResult.result,
                    },
                ])
            }
        } else {
            setMessages((m) => [...m, result])
        }
    }
    return (
        <Box flex alignItems="stretch" grow height="full" gap="m">
            <Box style={{ flexBasis: '33%' }}>
                <ChatBox
                    messages={messages}
                    onPostMessage={handlePostMessage}
                    placeholder="what do you want to build?"
                />
            </Box>
            <Box grow background="gray100" flex center justifyContent="center">
                <Box>Preview area</Box>
            </Box>
        </Box>
    )
}
