import { fetchWithAuth } from 'data/utils/fetchWithAuth'

import { ChatAssistantMessage, ChatMessageBase, ChatTool } from './chatTypes'

function buildTools(tools: ChatTool[]) {
    return tools.map((tool) => ({
        type: 'function',
        function: tool,
    }))
}

export async function getChatCompletion(
    messages: ChatMessageBase[],
    tools?: ChatTool[],
    outputSchema?: any
): Promise<ChatAssistantMessage> {
    const response = await fetchWithAuth('ai/', {
        method: 'POST',
        body: JSON.stringify({
            tools: tools ? buildTools(tools) : undefined,
            messages,
            response_format: outputSchema
                ? { type: 'json_schema', json_schema: outputSchema }
                : undefined,
        }),
        headers: { 'Content-Type': 'application/json' },
    })

    return (await response.json()) as ChatAssistantMessage
}
