import React from 'react'

import { Box } from 'ui/components/Box'
import { Skeleton } from 'ui/components/Skeleton'
import { Body } from 'ui/components/Text'
import { Tooltip } from 'ui/components/Tooltip'
import { ResponsiveValue, useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useTextAttributeDisplayState } from './hooks/useTextAttributeDisplayState'
import { AttributeDisplayComponent } from './types'
import { UrlAttributeDisplay } from './UrlAttributeDisplay'

type TextAttributeDisplayProps = React.ComponentPropsWithoutRef<typeof Body> & {
    openInNewTab?: boolean
    displayAsButton?: boolean
    buttonTitle?: string
    displayAsImage?: boolean
    imageAltText?: string
    treatAsUrl?: boolean
    maxOverflowLength?: number
    showOverflowTooltip?: ResponsiveValue<boolean>
    alwaysShowOverflowTooltip?: ResponsiveValue<boolean>
    isLoading?: boolean
}

export const TextAttributeDisplay: AttributeDisplayComponent<string, TextAttributeDisplayProps> =
    React.memo(function TextAttributeDisplay({
        value: providedValue,
        showOverflowTooltip,
        alwaysShowOverflowTooltip,
        maxOverflowLength,
        maxLength,
        isLoading,
        maxLines,
        openInNewTab,
        displayAsButton,
        buttonTitle,
        displayAsImage,
        imageAltText,
        treatAsUrl,
        color,
        size = 'm',
        ...props
    }) {
        const effectiveShowOverflowTooltip = useResponsiveValue(showOverflowTooltip)
        const effectiveAlwaysShowOverflowTooltip = useResponsiveValue(alwaysShowOverflowTooltip)
        const effectiveMaxLength = useResponsiveValue(maxLength)
        const effectiveMaxLines = useResponsiveValue(maxLines)

        const { isOverflowing, value } = useTextAttributeDisplayState({
            value: providedValue,
            maxLength: effectiveMaxLength,
            isLoading,
        })

        if (treatAsUrl) {
            return (
                <UrlAttributeDisplay
                    size={
                        size as React.ComponentPropsWithoutRef<typeof UrlAttributeDisplay>['size']
                    }
                    showOverflowTooltip={showOverflowTooltip}
                    alwaysShowOverflowTooltip={alwaysShowOverflowTooltip}
                    maxOverflowLength={maxOverflowLength}
                    value={providedValue}
                    isLoading={isLoading}
                    displayAsButton={displayAsButton}
                    buttonTitle={buttonTitle}
                    displayAsImage={displayAsImage}
                    imageAltText={imageAltText}
                    openInNewTab={openInNewTab}
                    maxLength={effectiveMaxLength}
                    maxLines={effectiveMaxLines}
                    {...props}
                />
            )
        }

        const content = (
            <Box minWidth={0} tabIndex={0} trim={!isLoading && effectiveMaxLines === 1}>
                <Skeleton isLoading={isLoading} display="block">
                    <Body
                        weight="regular"
                        maxLength={effectiveMaxLength}
                        maxLines={effectiveMaxLines}
                        color={color}
                        size={size}
                        {...props}
                    >
                        {value}
                    </Body>
                </Skeleton>
            </Box>
        )

        if (effectiveAlwaysShowOverflowTooltip || (isOverflowing && effectiveShowOverflowTooltip)) {
            return (
                <Tooltip
                    content={
                        <Body
                            style={{
                                fontSize: 'inherit',
                                fontWeight: 'inherit',
                                lineHeight: 'inherit',
                            }}
                            maxLength={maxOverflowLength}
                        >
                            {value}
                        </Body>
                    }
                    zIndex={99}
                    side="bottom"
                    asChild
                >
                    {content}
                </Tooltip>
            )
        }

        return content
    })
