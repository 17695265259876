import React, { forwardRef, useMemo } from 'react'

import { Avatar } from 'ui/components/Avatar'
import { IconNameVariantType } from 'ui/components/Icon/Icon'
import { AvatarStyle } from 'ui/components/Menu/Menu.css'
import { Body } from 'ui/components/Text'

import { MenuIcon } from './Dropdown.parts'
import { DropdownCheckboxBase } from './DropdownCheckboxBase'
import { DropdownItemBase } from './DropdownItemBase'

type IconProps = IconNameVariantType & {
    style?: React.CSSProperties
}
type AvatarProps = React.ComponentPropsWithoutRef<typeof Avatar>

type DropdownItemRef = HTMLDivElement

type BaseProps = React.ComponentPropsWithoutRef<typeof DropdownItemBase> &
    React.ComponentPropsWithoutRef<typeof DropdownCheckboxBase>

type DropdownItemProps = Omit<BaseProps, 'children' | 'leftSlotContent' | 'variant'> & {
    startIcon?: IconProps
    startAvatar?: AvatarProps
    label?: string | React.ReactNode
    multiSelect?: boolean
    variant?: 'default' | 'destructive' | 'action'
}

export const DropdownItem = forwardRef<DropdownItemRef, DropdownItemProps>(
    ({ startIcon, startAvatar, rightSlotContent, label, multiSelect, variant, ...props }, ref) => {
        const BaseComponent = multiSelect ? DropdownCheckboxBase : DropdownItemBase

        const iconColor: React.ComponentPropsWithoutRef<typeof MenuIcon>['color'] = useMemo(() => {
            if (props.disabled) return undefined

            switch (variant) {
                case 'destructive':
                    return 'iconError'
                case 'action':
                    return 'iconTheme'
                default:
                    return undefined
            }
        }, [props.disabled, variant])

        const labelColor: React.ComponentPropsWithoutRef<typeof Body>['color'] = useMemo(() => {
            if (props.disabled) return undefined

            switch (variant) {
                case 'destructive':
                    return 'textError'
                case 'action':
                    return 'textTheme'
                default:
                    return undefined
            }
        }, [props.disabled, variant])

        return (
            <BaseComponent
                {...props}
                variant={variant as any}
                ref={ref}
                leftSlotContent={
                    startIcon || startAvatar
                        ? () => (
                              <>
                                  {startIcon && (
                                      <MenuIcon size="m" color={iconColor} {...startIcon} />
                                  )}
                                  {startAvatar && (
                                      <Avatar
                                          size="s"
                                          shape="circle"
                                          {...(startAvatar as any)}
                                          className={AvatarStyle}
                                      />
                                  )}
                              </>
                          )
                        : undefined
                }
                rightSlotContent={rightSlotContent}
            >
                <Body
                    size="m"
                    weight="regular"
                    color={labelColor}
                    style={{ lineHeight: 'inherit' }}
                >
                    {label}
                </Body>
            </BaseComponent>
        )
    }
)
