import React from 'react'

import { refetchStacks } from 'data/hooks/stacks/stackOperations'
import { fetchWithAuth } from 'data/utils/fetchWithAuth'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'

import { bundleBuilder } from './bundles/bundleBuilder'

export const BundleImporter = ({
    bundle,
    setBundle,
}: {
    bundle: string
    setBundle: (bundle: string) => void
}) => {
    const handleImport = async () => {
        const response = await fetchWithAuth('bundles/import/', {
            method: 'POST',

            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ bundle }),
        })

        const data = await response.json()
        console.log(data)

        await refetchStacks()
        alert('Imported')
    }

    const handleConvert = () => {
        const simpleBundle = JSON.parse(bundle)
        const result = bundleBuilder(simpleBundle)
        setBundle(JSON.stringify(result, null, 2))
    }

    const isSimpleSchema = bundle.includes('"apps":') && !bundle.includes('"fileVersion":')
    const isFullSchema = bundle.includes('"apps":') && bundle.includes('"fileVersion":')

    return (
        <Box flex column height="full" gap="m" p="m">
            <Box
                as="textarea"
                value={bundle}
                onChange={(e) => setBundle(e.target.value)}
                grow
                height="full"
                border
                p="m"
            />
            <Box flex gap="m">
                <Button onClick={handleImport} disabled={!isFullSchema}>
                    Import
                </Button>
                <Button onClick={handleConvert} disabled={!isSimpleSchema}>
                    Convert from Simple Schema
                </Button>
            </Box>
        </Box>
    )
}
