import React, { useState } from 'react'

import { ChatMessageBase } from 'features/AiPlayground/chatUtils/chatTypes'

import { useScrollIntoView } from 'v2/ui/hooks/useScrollIntoView'

import { Box, BoxProps } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Container } from 'ui/components/Container'
import { Input } from 'ui/components/Input'

import './chatBoxStyles.css'

type ChatBoxProps = {
    onPostMessage: (message: string) => Promise<void>
    messages: ChatMessageBase[]
    placeholder?: string
}

export function ChatBox({
    onPostMessage,
    messages,
    placeholder = 'enter a message',
}: ChatBoxProps) {
    const [inputValue, setInputValue] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    async function handleSendMessage() {
        setIsLoading(true)
        const promise = onPostMessage(inputValue)
        setInputValue('')
        await promise
        setIsLoading(false)
    }
    return (
        <Box flex column alignItems="stretch" height="full">
            <Box overflowY="auto" grow flex column gap="s">
                {messages.map((message, index) => (
                    <ChatMessageItem key={index} message={message} />
                ))}
                {isLoading && <TypingIndicator />}
                <ScrollIntoView key={messages.length} mt="l" />
            </Box>
            <Box flex alignItems="center">
                <Input
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    placeholder={placeholder}
                    name="question"
                    autoComplete="off"
                    style={{ borderTopRightRadius: '0px', borderBottomRightRadius: '0px' }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSendMessage()
                        }
                    }}
                />
                <Button
                    startIcon={{ name: 'Send' }}
                    style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }}
                    onClick={handleSendMessage}
                />
            </Box>
        </Box>
    )
}

function TypingIndicator(props: BoxProps) {
    return (
        <Box className="typing-indicator" {...props}>
            <span></span>
            <span></span>
            <span></span>
        </Box>
    )
}

function ChatMessageItem({ message }: { message: ChatMessageBase }) {
    if (!message.content) return null

    return (
        <Container
            p="m"
            variant={message.role == 'user' ? 'accentMuted' : 'neutralMuted'}
            alignSelf={message.role === 'user' ? 'flex-end' : 'flex-start'}
        >
            {message.content}
        </Container>
    )
}

function ScrollIntoView(props: BoxProps) {
    const ref = useScrollIntoView()
    return <Box ref={ref} {...props} />
}
