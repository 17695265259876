// @ts-strict-ignore
import React, { useRef, useState } from 'react'

import { useObject } from 'data/hooks/objects'
import { FieldEditorPopover } from 'features/admin/fields/FieldEditorPopover'

import { Button } from 'v2/ui'

type CreateFieldPopoverProps = {
    object: ObjectDto
    onFieldCreated?: (field: any) => Promise<unknown>
    usePortal?: boolean
    buttonVariant: string
    cta: string
    className?: string
}

const CreateFieldPopover: React.FC<CreateFieldPopoverProps> = ({
    object,
    onFieldCreated,
    buttonVariant,
    className,
    cta,
}) => {
    const [isOpen, setIsOpen] = useState<boolean>()

    const { fetch: _fetchObjects } = useObject(object?._sid)
    const targetRef = useRef()

    const handleAddField = (e): void => {
        setIsOpen(!isOpen)
        e.preventDefault()
        e.stopPropagation()
    }

    const onClose = () => {
        setIsOpen(false)
    }

    const handleFieldCreated = async (newField: FieldDto): Promise<void> => {
        await _fetchObjects()
        await onFieldCreated?.(newField)
        setIsOpen(false)
    }

    const ButtonComponent = Button
    return (
        <>
            <ButtonComponent
                ref={targetRef}
                onClick={handleAddField}
                style={{
                    height: 30,
                }}
                variant={buttonVariant}
                p={2}
                className={className}
                size="sm"
                icon="add"
            >
                {cta}
            </ButtonComponent>
            <FieldEditorPopover
                objectId={object?._sid}
                open={isOpen}
                target={targetRef.current}
                onSuccess={handleFieldCreated}
                onCancel={onClose}
                onClose={onClose}
                placement="bottom-start"
            />
        </>
    )
}

export default CreateFieldPopover
