import { ChatCompletionMessageToolCall } from 'openai/resources'

export async function handleToolCall(
    handlers: Record<string, (args: any) => any>,
    tool_call: ChatCompletionMessageToolCall
) {
    const tool_name = tool_call.function.name
    const tool_args = JSON.parse(tool_call.function.arguments)

    if (!(tool_name in handlers)) {
        throw new Error(`No handler for tool ${tool_name}`)
    }

    const result = await handlers[tool_name](tool_args)

    return {
        tool_call_id: tool_call.id,
        result,
    }
}
